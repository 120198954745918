.table-container {
    margin-bottom: 40px;
    display: flex;
    width: 100%;
}

/* Context menu styles */
.action-icon {
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.action-icon::after {
    content: '⋮';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.action-icon:hover::after {
    content: '⋮';
}

/* Add hover effect to the table rows */
.table-container table tbody tr:hover {
    background-color: #f5f5f5;
}

.select-box{
}

.file-name-cell {
    width: 400px;
    white-space: nowrap;
    position: relative;
}

.file-upload-time-cell {
    white-space: nowrap;
    width: 200px;
}

.actions-cell {
    width: 100px;
}

.file-name-cell:hover::after {
    content: attr(data-file-name);
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #f9f9f9;
    padding: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    white-space: normal;
    max-width: 400px;
  }

  .tooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .tooltip:hover {
    white-space: normal;
    overflow: visible;
  }
  
.actions-container {
    position: relative;
    display: flex;
    align-items: center;
}

.action-icon {
    cursor: pointer;
}

.overlay {
    position: absolute;
    top: 100%;
    left: 0;
    width: 120px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 4px;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.overlay-item {
    cursor: pointer;
    padding: 4px;
}

.overlay-item:hover {
    background-color: #f5f5f5;
}