.header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 20px;
  background-color: #f0f0f0;
  z-index: 10;
}

.header-links {
  display: flex;
  align-items: center;
}

.header-link {
  margin-right: 20px;
  text-decoration: none; 
  color: black;
}

.dropdown-overlay {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  border: transparent;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 150px; 
}

.dropdown-overlay button {
  margin-bottom: 10px;
  min-height: 30px;
}

.overlay-menu-item {
  width: 100%;
}

.header-user-link img {
  margin-left: 5px;
  width: 20px; /* Set the desired width */
  height: 20px; /* Set the desired height */
}
