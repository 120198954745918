.signin {
  margin: 25vh auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signin-login-button {
  display: block;
  width: 85%;
  height: 40px;
  background-color: #8282f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 12px;
  font-size: 16px;
}

.signin-small-text {
  font-size: 14px;
  color: #888;
}

.signin-small-text a {
  color: #3498db;
  text-decoration: none;
}

.signin-small-text2 {
  font-size: 14px;
  color: #888;
  margin-top: 1px;
}

.signin-small-text2 a {
  color: #3498db;
  text-decoration: none;
}

.signin-error-message {
  color: red;
  margin-top: -20px;
  margin-bottom: 12px;
  font-size: 12px;
  text-align: left;
  width: 100%;
}

/* Media Queries */

@media (max-width: 768px) {
  .signin {
    margin: 10vh auto;
    max-width: 100%;
  }

  .signin-login-button {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .signin {
    margin: 5vh auto;
  }
}
