.privacy-container {
  line-height: 2.5;
  margin-left: 30px;
  margin-right: 30px;
}

.privacy-heading {
  font-weight: bold;
  margin-bottom: 10px;
}

.privacy-policy {
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .privacy-container {
    margin: 0 10px;
  }

  .privacy-heading {
    font-size: 18px;
  }

  .privacy-policy {
    margin-bottom: 30px;
  }
}
