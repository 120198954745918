.search-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
}

.search-bar input {
  padding: 8px;
  border-radius: 40px;
  border: 2px solid #ccc;
  height: 40px;
  width: 100%;
  font-size: 1rem;
}

.search-bar-input {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  width: 60%;
  border-radius: 200px;
}

.search-bar-input input.drag-over-input {
  background-color: aliceblue;
}

.search-bar-buttons {
  display: flex;
}

.search-bar-button {
  background-color: #8282f5;
  color: white;
  width: 200px;
  height: 50px;
  display: block;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 8px;
  font-size: 16px;
}

.search-bar-button:first-child {
  margin-right: 4px;
}

.search-bar-button:last-child {
  margin-left: 10px;
}

.spinner {
  display: inline-block;
  width: 32px;
  height: 32px;
  border: solid transparent;
  animation: spinner-rotation 20s infinite linear;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spinner-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-top-color: #f15608;
  border-radius: 50%;
  animation: spinner-gradient 1s infinite linear;
}

@keyframes spinner-gradient {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #0bd408;
  }
}


