.aboutpage-mission-text {
  font-size: 25px;
  color: #000;
  text-align: center;
  margin-bottom: 150px;
  font-weight: bold;
}


.aboutpage-organize-text {
  display: flex;
  font-size: 18px;
  color: #000;
  text-align: left;
  line-height: 2.5;
  margin-left: 50px;
}

.aboutpage-organize-description {
  margin-right: 120px;
}
.aboutpage-organize-text-question {
  color:#0202f9;
}

.aboutpage-organize-image {
  width: 400px;
  height: 400px;
  margin-right: 110px;
  margin-top: -20px;
}

.aboutpage-search-text {
  display: flex;
  font-size: 18px;
  color: #000;
  text-align: left;
  line-height: 2.5;
  margin-left: 50px;
  margin-top: 100px;
}

.aboutpage-search-description {
  margin-left: 250px;
  margin-right: 50px;
}
.aboutpage-search-text-question {
  color:#0202f9;
}

.aboutpage-search-image {
  width: 400px;
  height: 400px;
  margin-left: 50px;
  margin-top: 30px;
}

.aboutpage-monetize-text {
  display: flex;
  font-size: 18px;
  color: #000;
  text-align: left;
  line-height: 2.5;
  margin-left: 50px;
  margin-top: 100px;
}

.aboutpage-monetize-description {
  margin-left: 50px;
  margin-right: 200px;
  margin-bottom: 70px;
}
.aboutpage-monetize-text-question {
  color:#0202f9;
}

.aboutpage-monetize-image {
  width: 400px;
  height: 400px;
  margin-right: 150px;
  margin-top: 40px;
  margin-bottom: 120px;
}