.passwordreset {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .passresetContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .passresetContainer .passresetText {
    height: 30px;
    width: 300px;
  }
  
  .passresetButton {
    background-color: #8282f5;
    color: white;
    width: 300px;
    height: 50px;
    display: block;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .passwordreset input,
  .passwordreset button {
    margin-bottom: 10px;
  }

  .passresetContainer .passresetOTP {
    width: 300px;
    height: 30px;
    padding: 5px;
    margin-bottom: 1rem;
    font-size: large;
    font-weight: bold;
    letter-spacing: 10px;
    text-align: center;
}

  
  .passresetButton {
    padding: 10px 20px;
    background-color: #8282f5;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .passwordreset button1:hover {
    background-color: #8282f5;
  }
  