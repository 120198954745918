.landing {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  flex-direction: column;
}


.landing-buttons {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.landing-login-button,
.landing-signup-button {
  flex: 1;
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #8282f5;
  color: white;
  border: none;
  outline: none;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .landing {
    margin-top: 5vh;
  }

  .landing-buttons {
    margin-top: 5px;
    flex-direction: column;
  }

  .landing-login-button,
  .landing-signup-button {
    margin: 5px 0;
  }
}