.popup-overlay {
  position: fixed;
  bottom: 70px;
  left: 20px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
  border: 2px solid hsl(0, 8%, 75%);
  width: 250px;
  height: 40px;
  max-width: 350px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  display: flex;
  align-items: center;
}

.popup-overlay .close-button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  margin-right: 1px;
}