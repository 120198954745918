.footer {
    background-color: #f1f1f1;
    padding: 15px 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  
  .footer-nav a {
    color: #333;
    text-decoration: none;
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }

  .separator {
    margin: 0 5px; /* Adjust the margin as needed */
  }
  
  