.prelanding {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 30vh;
}

.prelanding-text-content {
  margin-bottom: 20px;
}

.digit-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 40%;
  margin: 0 auto;
}

.digit-boxes input {
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  margin: 0 5px;
}

prelanding-button {
  padding: 10px 20px;
  background-color: #8282f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 200px;
  height: 50px;
}

prelanding-button:hover {
  background-color: #6c6cd9;
}

prelanding-button:active {
  background-color: #5555a8;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .landing {
    margin-top: 5vh;
  }

  .landing-buttons {
    margin-top: 5px;
    flex-direction: column;
  }

  .landing-login-button,
  .landing-signup-button {
    margin: 5px 0;
  }
}