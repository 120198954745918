.TOS-container {
    line-height: 2.5;
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .TOS-heading {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .TOS-container {
      margin: 0 10px;
    }
  
    .TOS-heading {
      font-size: 18px;
    }
  }
  