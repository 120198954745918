/* Base styles */
.signup {
  margin: 10vh auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
}

.signup-text {
  height: 20px;
}

.signup-small-text {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signup-continue-button {
  display: block;
  width: 85%;
  padding: 10px;
  background-color: #8282f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  font-size: 16px;
}

.signup-small-text a {
  color: #3498db;
  text-decoration: none;
}

.signup-divider {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.signup-divider-line {
  flex-grow: 1;
  height: 1px;
  background-color: #000;
}

.signup-divider-text {
  margin: 0 10px;
  color: #888;
}

.signup-social-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.signup-social-button {
  display: flex;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.signup-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Media Queries */
@media only screen and (max-width: 600px) {
  .signup {
    margin: 10vh 5%;
    max-width: 90%;
  }
  
  .signup-continue-button {
    width: 100%;
  }
  
  .signup-social-button {
    width: 100%;
  }
  
  .signup-divider-line {
    width: 100%;
  }
  
  .signup-divider-text {
    margin: 0 5px;
  }
}
