.home-container {
  position: fixed;
  margin: 0vh auto;
  text-align: center;
  max-width: 1450px;
  width: 100%;
}

.home-text-content {
  font-size: 36px;
  color: #8282f5;
  margin-bottom: 10px;
}

.home-searchbar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.carousel-container {
  position: relative;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-images {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 90%;
  margin-bottom: 20px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: fit;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.carousel-image.active {
  opacity: 1;
}

.carousel-navigation {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.carousel-circle.active {
  background-color: #888;
}

/* Media Queries */
@media (max-width: 768px) {
  .home-container {
    /* Adjust the styling for smaller screens */
  }
  
  .home-searchbar {
    /* Adjust the styling for smaller screens */
  }
}

@media (max-width: 480px) {
  .home-container {
    /* Adjust the styling for even smaller screens */
  }
  
  .home-searchbar {
    /* Adjust the styling for even smaller screens */
  }
}