.searchresults-page-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.searchresults-solid-bar-frame {
    background-color: white;
    width: 100%;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
}

.searchresults-dash-header-container {
    width: 100%;
}

.searchresults-dash-sage-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

.searchresults-dash-search-container {
    margin-top: -20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
}

.searcharea-container {
    margin-top: 35vh;
}

.searchresults-horizontal-line {
    width: 100%;
    border-top: 1px solid #ffffff; 
    margin: 10px 0;
}

.searchresults-dashboard-frame {
    margin-left: 30px;
    margin-right: 30px;
    overflow: auto;
    max-height: 800px;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: transparent transparent; /* For Chrome, Edge, and Safari */
}

/* For Chrome, Edge, and Safari */
.searchresults-dashboard-frame::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

/* For Chrome, Edge, and Safari */
.searchresults-dashboard-frame::-webkit-scrollbar-thumb {
  background-color: transparent;
}