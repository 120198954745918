.otpContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.resendText {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.otpContainer .otpInput {
    width: 200px;
    height: 30px;
    padding: 5px;
    margin-bottom: 1rem;
    font-size: larger;
    font-weight: bold;
    letter-spacing: 10px;
    text-align: center;
}

.otpButton {
    background-color: #8282f5;
    color: white;
    width: 200px;
    height: 50px;
    display: block;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}