.page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.solid-bar-frame {
  background-color: white;
  height: 250px;
  width: 100%;
  position: fixed;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 200px;
  border-bottom: 1px solid #dde1e4; 
  margin-top: -15px;
  gap: 10px;
}

.dash-header-container {
  width: 100%;
}

.dash-sage-container {

}

.dash-search-container {
  margin-top: -20px;
}


.dashboard-frame {
  margin-top: 35vh;
  width: 80%
}