/* Base styles */
.signup-pass {
  margin: 20vh auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
}

.signup-pass input[type="password"],
.signup-pass input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signup-pass-textbox {
  width: 100%;
  max-width: 500px;
}

.signup-pass-small-text {
  font-size: 14px;
  color: #888;
  margin-bottom: 1px;
}

.signup-pass-checkbox {
  margin-top: 10px;
}

.phone-input-wrapper {
  width: 60%;
}

.phone-input-wrapper input {
  height: 30px;
  width: 100%;
  border: 1px solid #ccc;
}

.signup-pass_button {
  width: 60%;
  padding: 10px;
  background-color: #8282f5;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  margin-top: 20px;
}

/* Media Queries */
@media only screen and (min-width: 500px) {
  .signup-pass input[type="password"],
  .signup-pass input[type="text"] {
    width: 55%;
  }

  .signup-pass-textbox {
    width: 500px;
  }
}

@media only screen and (max-width: 500px) {
  .signup-pass {
    margin: 10vh auto;
  }

  .signup-pass input[type="password"],
  .signup-pass input[type="text"] {
    width: 80%;
  }

  .signup-pass-textbox {
    width: 80%;
    max-width: none;
  }

  .phone-input-wrapper input {
    width: 80%;
    max-width: none;
  }

  .signup-pass_button {
    width: 80%;
  }
}
