.searchresultcontainer {
}
.searchitem {
    margin-bottom: 20px;
    border: 1px solid #e1d8d8;
    padding: 10px;
}

.emptysearchitem {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
}